<template>
  <!--微信菜单栏: 线下活动-->
  <div class="root">
    <!--轮播图骨架-->
    <v-skeleton-loader
      class="mx-5 mt-3"
      type="card"
      elevation="2"
      v-if="!newsList.length"
    ></v-skeleton-loader>

    <!--轮播图部分-->
    <swiper :options="swiperOption" ref="mySwiper" class="swiper-container" v-if="newsList.length">
      <swiper-slide v-for="item in newsList.slice(0, 3)" :key="item.title" class="mb-1 mt-3">
        <a :href="item.link">
          <v-card class="mx-5" elevation="0">
            <v-img height="140px"
                   class="white--text align-end"
                   :src="`/apis/wx/iamge.do?newId=${item.uid}`">
            </v-img>
            <v-card-title class="d-block text-truncate card-title py-2" style="text-indent: -0.5em">{{item.title}}</v-card-title>
          </v-card>
        </a>
      </swiper-slide>
<!--      <div class="swiper-pagination" slot="pagination"></div>-->
    </swiper>
    <!--新闻列表-->
    <m-news-list :news-list="newsList"></m-news-list>

    <!--加载中提示-->
    <v-progress-circular
      indeterminate
      color="primary"
      class="loading"
      v-if="loading"
    ></v-progress-circular>
  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import {swiper, swiperSlide} from 'vue-awesome-swiper'
  import MNewsList from "@/components/common/MNewsList"
  import TimeFormatter from "@/utils/timeFormatter"

  export default {
    name: "MCategory1",
    components: {
      swiper,
      swiperSlide,
      MNewsList
    },
    metaInfo: {
      title: '财商少年|线下活动',
    },
    data() {
      return {
        swiperOption: {
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          autoplay: true
        },
        newsList: [],
        totalPage: 3,
        page: 0,
        loading: false,
        limit: 10 // 每次加载多少页
      }
    },
    methods: {
      // 获取更多新闻
      getNews() {
        this.loading = true
        this.$axios.news.getNews({
          sort: 'relTime',
          types: 'xxhd',
          page: this.page,
          limit: this.limit
        }).then(res => {
          this.newsList = this.newsList.concat(res.data.list.map(item => {
            item.relTime = new TimeFormatter(item.relTime).getMacroTime()
            return item
          }))
          this.$nextTick(() => {
            this.loading = false
            let scrollY = window.scrollY
            let pageHeight = window.innerHeight
            window.scrollTo({
              top: scrollY + pageHeight,
              behavior: "smooth"
            });
          })
        })
      }
    },
    created() {
      this.$axios.news.getNewsFast({
        sort: 'relTime',
        types: 'xxhd',
        page: 0,
        limit: this.limit
      }).then(res => {
        console.log(res)
        this.totalPage = Math.ceil(res.data.total / this.limit) // 计算总页数
        this.newsList = this.newsList.concat(res.data.list.map(item => {
          item.relTime = new TimeFormatter(item.relTime).getMacroTime()
          return item
        }))
        // 拿到数据后, 开始监听触底
        this.$nextTick(() => {
          document.addEventListener('scroll', () => {
            // 当前滚动距离
            let scrollY = window.scrollY
            // 一页高度
            let pageHeight = window.innerHeight
            // 页面总高度
            let totalHeight = document.body.scrollHeight
            if(scrollY + pageHeight === totalHeight && ++this.page < this.totalPage) {
              this.getNews()
            }
          })
        })
      })
    }
  }
</script>

<style lang="scss" scoped>
  .root {
    font-family: "Microsoft YaHei UI Light", "Microsoft YaHei UI", "Microsoft YaHei", sans-serif !important;
  }

// 切换轮播图
::v-deep .swiper-pagination {
  bottom: 5px;
}
.card-title {
  font-size: 16px;
  background-color: #f7f7f7;
}

// 占两行的标题
.two-line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

  // 加载中提示
  .loading {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
</style>

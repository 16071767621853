<template>
  <div>
    <!--新闻列表骨架-->
    <template v-if="!newsList.length">
      <template  v-for="n in 3">
        <v-skeleton-loader
          class="mx-4 my-4 d-flex"
          type="paragraph, avatar"
          tile
          :key="n"
        ></v-skeleton-loader>
        <v-divider :key="'haha'+ n" class="divider"></v-divider>
      </template>
    </template>

    <v-list three-line class="root pt-0" v-if="newsList.length">
      <template v-for="(item, index) in newsList">
        <a :href="item.link" target="_blank" :key="item.title">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="article-title grey--text text--darken-4">{{item.title}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{item.relTime}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar size="75" width="100px"
                                tile>
              <v-img :src="`/apis/wx/iamge.do?newId=${item.uid}`"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </a>
        <v-divider :key="index" v-if="index !== newsList.length" class="divider"></v-divider>
      </template>
    </v-list>
  </div>

</template>

<script>
  export default {
    name: "MNewsList",
    props: {
      newsList: {
        type: Array,
        required: true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .root {
    font-family: "Microsoft YaHei UI Light", "Microsoft YaHei UI", "Microsoft YaHei", sans-serif !important;
  }
  .article-title {
    font-size: 16px;
    text-align: justify;
    line-height: 1.5em;
  }
  // 骨架
  ::v-deep .v-skeleton-loader__avatar {
    width: 100px;
    height: 75px;
    margin-left: 20px;
  }

  .divider {
    border-color: #f9f9f9;
    margin: 0 16px;
  }
</style>
